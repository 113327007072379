import * as React from "react";
import {
  styledMap,
  styledPointList,
  column1,
  column2,
  styledDistanceFilter,
  contentWrapper,
} from "./PointsOfInterest.module.scss";
import { MapContainer } from "react-leaflet";
import { isBrowser } from "../../../utils/helpers";
import { TPoint } from "./PointsOfInterestProps";

export const StyledMap: React.FC<any> = (props) => {
  return isBrowser ? (
    <MapContainer
      {...props}
      className={`${styledMap} mb-3 `}
      id="point-of-interest-map"
    >
      {props.children}
    </MapContainer>
  ) : null;
};

export const StyledPointList: React.FC<{
  columns: boolean;
  children: React.ReactNode;
  points: Array<TPoint>;
}> = (props) => {
  return (
    <div
      className={`${styledPointList} ${props.columns ? column2 : column1} ${
        props.points?.length !== 0 ? "d-block my-2" : "d-none"
      }`}
    >
      <ol>{props.children}</ol>
    </div>
  );
};

export const StyledDistanceFilter: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  return <div className={styledDistanceFilter}>{props.children}</div>;
};
export const ContentWrapper: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  return <div className={contentWrapper}>{props.children}</div>;
};
