// extracted by mini-css-extract-plugin
export var addressContainer = "About-module--addressContainer--88b80";
export var alt = "About-module--alt--82ea1";
export var container = "About-module--container--1788f";
export var descContainer = "About-module--descContainer--cdcff";
export var description = "About-module--description--163e7";
export var desktopOnly = "About-module--desktopOnly--6cd54";
export var divider = "About-module--divider--93efa";
export var main = "About-module--main--2f4d2";
export var mobileOnly = "About-module--mobileOnly--92fcf";
export var readMore = "About-module--readMore--24afd";
export var textLeft = "About-module--textLeft--c044f";
export var time = "About-module--time--93d2d";
export var titleContainer = "About-module--titleContainer--eb6ee";
export var visuallyHidden = "About-module--visuallyHidden--ff51d";