import React from "react";
import { Col } from "react-bootstrap";
import RoomCard from "../../../RoomCard/RoomCard";
import { RoomItemProps } from "../HotelRoomsHelpersProps";

const RoomItem: React.FC<RoomItemProps> = ({
  room,
  idx,
  handleSelectRoom,
  updatingRates,
  isGroupCodeValid,
  hotel,
  hotelInfoUrgBgColor,
  hotelInfoUrgFontColor,
  redemptionItem,
  isMobileOnly,
}) => (
  <Col key={`${idx}-hr-${room.RoomCode}`} className="mb-4">
    <RoomCard
      room={room}
      selectRoom={handleSelectRoom}
      loadingRates={updatingRates}
      isGroupCodeValid={isGroupCodeValid}
      hotel={hotel}
      hotelInfoUrgBgColor={hotelInfoUrgBgColor}
      hotelInfoUrgFontColor={hotelInfoUrgFontColor}
      roomCardId={!isMobileOnly ? idx + 1 : undefined}
      redemptionItem={redemptionItem}
    />
  </Col>
);

export default RoomItem;
