import * as React from "react";
import { useEffect } from "react";
import Col from "../../../../components/global/Col/Col";
import {
  container,
  titleContainer,
  descContainer,
  divider,
  time,
  description,
  readMore,
  addressContainer,
} from "./About.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

export const Container = (props: { children: any }): JSX.Element => {
  return <div className={container}>{props.children}</div>;
};

export const TitleContainer = (props: { children: any }): JSX.Element => {
  return <Col className={`col4 ${titleContainer}`}>{props.children}</Col>;
};

export const DescContainer = (props: { children: any }): JSX.Element => {
  return <Col className={`col7 ${descContainer}`}>{props.children}</Col>;
};

export const Time = (props: {
  checkInTime: string;
  checkOutTime: string;
}): JSX.Element => {
  return (
    <div className={time}>
      <span id={"checkin-time"}>Check-in: {props.checkInTime}</span>

      <div className={divider}></div>
      <span id={"checkout-time"}>Checkout: {props.checkOutTime}</span>
    </div>
  );
};

export const Description = (props: {
  text: string;
  themeColor: string;
}): JSX.Element => {
  const TRIMLENGTH = 375;
  const aboutText = props.text || "";
  const isDescToTrim = aboutText.length > TRIMLENGTH;
  const [isFullText, setIsFullText] = React.useState(false);
  const handleReadMore = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsFullText(true);
  };
  const handleReadLess = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsFullText(false);
  };
  useEffect(() => {
    if (isDescToTrim) {
      setIsFullText(false);
    } else {
      setIsFullText(true);
    }
  }, []);

  return (
    <>
      <div className={description}>
        <div
          className={!isFullText ? "content" : ""}
          dangerouslySetInnerHTML={{ __html: aboutText }}
        />
      </div>
      {aboutText?.length > TRIMLENGTH ? (
        !isFullText ? (
          <a
            className={readMore}
            id="about-read-more"
            onClick={handleReadMore}
            href="#"
            aria-expanded={false}
            aria-label="Read more"
            style={{
              textDecorationColor: "black",
            }}
          >
            Read More +
          </a>
        ) : (
          <a
            className={readMore}
            id="about-read-less"
            onClick={handleReadLess}
            aria-expanded={true}
            aria-label="Read Less"
            href="#"
            style={{
              textDecorationColor: "black",
            }}
          >
            Read Less -
          </a>
        )
      ) : null}
    </>
  );
};

export const AddressBar = (props: {
  directionLink: string;
  phone: string;
  hotelInfoFontColor: string;
  address: {
    address_line1?: string;
    locality?: string;
    administrative_area?: string;
    postal_code?: string;
  };
}): JSX.Element => {
  const _address = props.address;
  const _directionsLink = props.directionLink;
  const _phone = props.phone;
  return (
    <div className={addressContainer}>
      <div>
        <a
          href={_directionsLink}
          target="_blank"
          rel="noopener noreferrer"
          id="hotel-address"
        >
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            color={props.hotelInfoFontColor}
            className="me-2"
          />
          <span style={{ color: props.hotelInfoFontColor }}>
            {_address.address_line1}, {_address.locality},{" "}
            {_address.administrative_area} {_address.postal_code}
          </span>
        </a>
      </div>
      {_phone !== null && (
        <div className="mt-2">
          <a
            href={`tel:${_phone}`}
            style={{ color: props.hotelInfoFontColor }}
            id="hotel-phone"
          >
            <FontAwesomeIcon
              icon={faPhoneAlt}
              className="me-1"
              color={props.hotelInfoFontColor}
            />
            <span style={{ color: props.hotelInfoFontColor }}>{_phone}</span>
          </a>
        </div>
      )}
    </div>
  );
};
