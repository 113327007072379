// extracted by mini-css-extract-plugin
export var alt = "FeaturesTabs-module--alt--8c556";
export var desktopOnly = "FeaturesTabs-module--desktopOnly--45991";
export var featureDescription = "FeaturesTabs-module--featureDescription--8ec22";
export var main = "FeaturesTabs-module--main--a2ad6";
export var mobileOnly = "FeaturesTabs-module--mobileOnly--e6445";
export var rFPButton = "FeaturesTabs-module--rFPButton--85d99";
export var styledNav = "FeaturesTabs-module--styledNav--87de7";
export var styledRow = "FeaturesTabs-module--styledRow--6fa86";
export var styledTabPanes = "FeaturesTabs-module--styledTabPanes--41e79";
export var textLeft = "FeaturesTabs-module--textLeft--275cc";
export var visuallyHidden = "FeaturesTabs-module--visuallyHidden--20adb";