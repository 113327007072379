import React from "react";
import RoomSort from "../../RoomSort/RoomSort";
import { RoomSortComponentProps } from "../HotelRoomsHelpersProps";

export const RoomSortComponent: React.FC<RoomSortComponentProps> = ({
  sortOrder,
  handleSortChange,
  setSortOrderValue,
  sortOrderValue,
}) => (
  <RoomSort
    sortOrder={sortOrder}
    handleSortChange={handleSortChange}
    setSortOrderValue={setSortOrderValue}
    sortOrderValue={sortOrderValue}
  />
);
