// src/components/global/JsonLd/JsonLd.tsx
import React from "react";
import { Helmet } from "react-helmet";
import IHotel from "../../../@types/IHotel";
import { getHotelJson, getPostalAddressJson } from "./JsonLdHelpers";

const JsonLd = ({ hotel, siteUrl }: { hotel: IHotel; siteUrl: string }) => {
  const hotelJson = getHotelJson(hotel, siteUrl);
  return (
    <Helmet>
      <script type="application/ld+json" className="http://schemantra.com">
        {JSON.stringify(hotelJson)}
      </script>
    </Helmet>
  );
};

export default JsonLd;
