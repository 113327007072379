// extracted by mini-css-extract-plugin
export var alt = "PointsOfInterest-module--alt--23449";
export var column1 = "PointsOfInterest-module--column1--215de";
export var column2 = "PointsOfInterest-module--column2--231b2";
export var contentWrapper = "PointsOfInterest-module--contentWrapper--45f28";
export var desktopOnly = "PointsOfInterest-module--desktopOnly--4f16d";
export var main = "PointsOfInterest-module--main--c5a9f";
export var mobileOnly = "PointsOfInterest-module--mobileOnly--37ed5";
export var popUpOverlay = "PointsOfInterest-module--popUpOverlay--fb57c";
export var styledDistanceFilter = "PointsOfInterest-module--styledDistanceFilter--9e311";
export var styledMap = "PointsOfInterest-module--styledMap--48b6e";
export var styledPointList = "PointsOfInterest-module--styledPointList--2ab7d";
export var textLeft = "PointsOfInterest-module--textLeft--11cdd";
export var visuallyHidden = "PointsOfInterest-module--visuallyHidden--d07cd";