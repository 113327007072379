import React, { useState, useEffect } from "react";
import { Tab, Col, Nav, Dropdown } from "react-bootstrap";
import { ImageCarousel } from "../ImageCarousel/ImageCarousel";
import {
  StyledNav,
  StyledTabPanes,
  StyledRow,
  Description,
} from "./FeaturesTabsHelpers";
import { gotoTab } from "../../../utils/helpers";
import useMobileDetect from "../../../hooks/useMobileDetect";
import "lazysizes";
import Img from "gatsby-image";
import { buildFluidImageData } from "@imgix/gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ISubFeatureTabLinks,
  ISubFeatureTabPanes,
  IFeatureImage,
  IFeatureTabPanes,
  IFeaturesTabs,
} from "./FeaturesTabsProps";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

interface ISubFeatures {
  tab_title: string;
  title: string;
  video_url: string;
  image: {
    alt: string;
    url: string;
  };
  description: string;
}
const SubFeatureTabLinks: React.FC<ISubFeatureTabLinks> = ({
  subFeatures,
  activeSubTab,
  tabKey,
  handleClickSubTab,
}) => {
  return (
    <div className="nested-tabs">
      {subFeatures.map((subfeature: ISubFeatures) => {
        const subTabKey = subfeature.tab_title
          ?.replace(/[^A-Z0-9]/gi, "-")
          ?.replace(/-+/g, "-")
          ?.toLowerCase();
        const activeClass = activeSubTab === subTabKey;

        return (
          <Nav.Item
            key={subfeature.tab_title}
            data-tab={subTabKey}
            // className={activeClass}
            id={`hoteldetails_${tabKey}_${subTabKey}`}
          >
            <Nav.Link
              onClick={() => handleClickSubTab(subTabKey)}
              eventKey={subTabKey}
              active={activeClass}
            >
              {subfeature.tab_title}
            </Nav.Link>
          </Nav.Item>
        );
      })}
    </div>
  );
};

const SubFeatureTabPanes = ({
  features,
  hotel,
  activeSubTab,
}: ISubFeatureTabPanes): JSX.Element[] => {
  return features.map((subfeature: any, index) => {
    const subTabKey = subfeature.tab_title
      ?.replace(/[^A-Z0-9]/gi, "-")
      ?.replace(/-+/g, "-")
      ?.toLowerCase();
    const idx = index + 1;
    // const subFeatureDesc = replaceGroupRFPLink(
    //   subfeature.description,
    //   hotel.field_cvent
    // );
    return (
      <Tab.Pane
        as="div"
        active={subTabKey === activeSubTab}
        key={subTabKey + idx}
        eventKey={subTabKey}
      >
        <FeatureImage image={subfeature.image} />
        <h2 className="feature-title">{subfeature.title}</h2>
        <Description
          cventid={hotel.field_cvent}
          text={subfeature.description}
        />
      </Tab.Pane>
    );
  });
};

const FeatureImage: React.FC<IFeatureImage> = ({ image }) => {
  if (image) {
    const url = decodeURIComponent(image.url);
    return (
      <Img
        className={"feature-image lazyload"}
        fluid={buildFluidImageData(
          url,
          {
            ar: 1.18,
            auto: ["format", "compress"],
          },
          {
            sizes: "100%",
          }
        )}
        alt={image.alt}
      />
    );
  }
  return null;
};
const capitalizeTitle = (title: string) => {
  return title.replace(/\b(\w)/g, (firstLetter) => firstLetter.toUpperCase());
};

const FeatureTabPanes = ({
  features,
  gallery,
  hotel,
  activeSubTab,
  activeTab,
}: IFeatureTabPanes) => {
  return features.map((feature: any, index: number) => {
    const tabKey = feature.tab_title
      ?.replace(/[^A-Z0-9]/gi, "-")
      ?.replace(/-+/g, "-")
      ?.toLowerCase()
      ?.trim();
    const subFeatures = feature.sub_features ? feature.sub_features : [];
    const cookiesEnabled =
      typeof window !== "undefined" &&
      window?.OnetrustActiveGroups?.includes("C0004")
        ? true
        : false;
    return (
      <React.Fragment key={tabKey}>
        <Tab.Pane
          eventKey={tabKey}
          active={!activeSubTab && activeTab === tabKey}
        >
          {index === 0 && gallery !== null && gallery.length ? (
            <ImageCarousel images={gallery} />
          ) : feature.video_url ? (
            cookiesEnabled ? (
              <VideoPlayer url={feature.video_url} />
            ) : null
          ) : (
            <FeatureImage image={feature.image} />
          )}
          <h2 className="feature-title">{feature.title}</h2>
          <Description
            cventid={hotel.field_cvent || ""}
            text={feature.description}
          />
        </Tab.Pane>
        {subFeatures ? (
          <SubFeatureTabPanes
            features={subFeatures}
            hotel={hotel}
            tabKey={tabKey}
            activeSubTab={activeSubTab}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  });
};
function getString(arr: Array<string>) {
  let string = "";
  for (let i = 0; i < arr.length; i++) {
    if (i === arr.length - 1) {
      return string + arr[i];
    }
    if (arr[i].match(/^[^a-zA-Z]+$/) && arr[i + 1].match(/^[^a-zA-Z]+$/)) {
      continue;
    } else {
      if (!arr[i].match(/^[^a-zA-Z]+$/)) {
        string += arr[i];
        continue;
      } else {
        string += "-";
      }
    }
  }
  return string?.toLowerCase();
}
const FeaturesTabs: React.FC<IFeaturesTabs> = ({
  features,
  gallery,
  hotel,
  location,
}) => {
  const isMobile = useMobileDetect();
  const _tabTitle = features[0]
    ? getString(features[0].tab_title?.toLowerCase())
    : "";
  const hashFeature: any =
    location && location.hash.split("_")[1]?.split("")
      ? getString(location.hash.split("_")[1]?.split(""))
      : "";
  const hashSubfeature =
    location && location.hash.split("_")[2]?.split("")
      ? getString(location.hash.split("_")[2]?.split(""))
      : "";
  // const hashFeatureArray = location ? location.hash.split("_")[0] : "";
  // if (hashFeatureArray.split("-").length > 4) {
  //     hashFeature = hashFeatureArray.split("-").slice(-2).join("-");
  // } else {
  //     hashFeature = hashFeatureArray ? hashFeatureArray.split("-")[3] : null;
  // }
  const [activeTab, setActiveTab] = useState(
    hashFeature ? hashFeature : _tabTitle
  );
  const [activeSubTab, setActiveSubTab] = useState(
    hashSubfeature ? hashSubfeature : ""
  );
  const handleClickTab = (value: string) => {
    setActiveSubTab("");
    setActiveTab(value);
  };
  const handleClickSubTab = (value: string) => {
    setActiveSubTab(value);
  };
  useEffect(() => {
    const hashFeature: any =
      location && location.hash.split("_")[1]?.split("")
        ? getString(location.hash.split("_")[1]?.split(""))
        : "";
    const hashSubfeature =
      location && location.hash.split("_")[2]?.split("")
        ? getString(location.hash.split("_")[2]?.split(""))
        : "";
    hashFeature && setActiveTab(hashFeature);
    setActiveSubTab(hashSubfeature);
  }, [location]);
  useEffect(() => {
    const id =
      activeTab && activeSubTab
        ? `hoteldetails_${activeTab}_${activeSubTab}`
        : `hoteldetails_${activeTab}`;
    if (document.getElementById(id) && (hashFeature || hashSubfeature)) {
      setTimeout(() => {
        gotoTab(id, isMobile);
      }, 1000);
    }
  }, [activeTab, activeSubTab]);

  const renderFeatureTabLinks = () => {
    return features.map((feature: any) => {
      const tabKey = feature.tab_title
        ?.replace(/[^A-Z0-9]/gi, "-")
        ?.replace(/-+/g, "-")
        ?.toLowerCase();
      const subFeatures = feature.sub_features
        ? feature.sub_features.filter(
            (subFeature: { tab_title: string }) => subFeature.tab_title !== ""
          )
        : [];
      return (
        <Nav.Item
          data-tab={tabKey}
          key={feature.tab_title}
          className={`${activeTab === tabKey ? "active" : ""} ${
            feature.sub_features !== null ? "has-children" : ""
          }`}
          id={
            activeTab && activeSubTab
              ? `hoteldetails_${activeTab}_${activeSubTab}`
              : `hoteldetails_${activeTab}`
          }
        >
          <Nav.Link
            eventKey={tabKey}
            data-tab={tabKey}
            onClick={(event: any) =>
              handleClickTab(event.currentTarget.dataset.tab)
            }
          >
            {feature.tab_title}
            {subFeatures.length > 0 && !isMobile && (
              <FontAwesomeIcon icon="chevron-down" />
            )}
          </Nav.Link>
          {subFeatures.length > 0 && isMobile && (
            <FontAwesomeIcon icon="chevron-down" />
          )}
          {subFeatures.length > 0 && (
            <SubFeatureTabLinks
              subFeatures={feature.sub_features}
              activeSubTab={activeSubTab}
              tabKey={tabKey}
              handleClickSubTab={handleClickSubTab}
            />
          )}
        </Nav.Item>
      );
    });
  };
  return (
    <Tab.Container
      defaultActiveKey={hashFeature ? hashFeature : _tabTitle}
      id="hoteldetails"
    >
      <StyledRow>
        <Col lg={4} className="featuresCol">
          {isMobile ? (
            <>
              <div className="visually-hidden">
                <StyledNav>{renderFeatureTabLinks()}</StyledNav>
              </div>
              <Dropdown>
                <Dropdown.Toggle className="custom-dropdown-toggle-features-tabs">
                  <div className="buttonText">{capitalizeTitle(activeTab)}</div>
                  <FontAwesomeIcon
                    icon="chevron-down"
                    className="dropdownArrow dropdown-arrow-feature-tabs"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <StyledNav>{renderFeatureTabLinks()}</StyledNav>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <StyledNav>{renderFeatureTabLinks()}</StyledNav>
          )}
        </Col>
        <Col lg={{ span: 8 }} className="contentCol">
          <StyledTabPanes>
            <Tab.Content>
              <FeatureTabPanes
                features={features}
                gallery={gallery}
                hotel={hotel}
                activeSubTab={activeSubTab}
                activeTab={activeTab}
              />
            </Tab.Content>
          </StyledTabPanes>
        </Col>
      </StyledRow>
    </Tab.Container>
  );
};

export default FeaturesTabs;
