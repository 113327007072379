import React from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { OfferMessageProps } from "./HotelRoomsHelpersProps";

const OfferMessage: React.FC<OfferMessageProps> = ({
  showOfferMessage,
  message,
  setShowOfferMessage,
  search,
}) => (
  <>
    {showOfferMessage && message && !search.discount && (
      <>
        <a
          className="anchor-target visually-hidden"
          id="offer-message"
          aria-hidden={true}
        />
        <Row className="px-lg-5">
          <Col>
            <Alert
              variant="danger"
              className="text-center"
              onClose={() => setShowOfferMessage(false)}
              dismissible
            >
              {message}
            </Alert>
          </Col>
        </Row>
      </>
    )}
  </>
);

export default OfferMessage;
