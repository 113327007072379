import React from "react";
import Row from "../../../../components/global/Row/Row";
import Title2 from "../../../../components/global/Title2/Title2";
import useMobileDetect from "../../../../hooks/useMobileDetect";
import {
  AddressBar,
  Container,
  DescContainer,
  Description,
  Time,
  TitleContainer,
} from "./AboutHelpers";
import { IAboutProps } from "./AboutProps";

const About = (props: IAboutProps): JSX.Element => {
  const hotel = props.hotel;
  const themeColor = props.hotel.relationships.brand_id?.field_color;
  const locality = props.hotel?.address?.locality;
  const checkInTime = hotel.field_check_in || "3:00 PM";
  const checkOutTime = hotel.field_check_out || "11:00 AM";
  let aboutText = "";
  let aboutTitle = "";
  const features = hotel.features;
  if (features && features.length) {
    const about = features.filter((f: any) => {
      const tabKey = f.tab_title?.toLowerCase().trim();
      return tabKey === "about";
    });
    aboutText = about[0] ? about[0]?.description : "";
    aboutTitle = about[0] ? about[0]?.title : `Welcome To ${locality}`;
  }
  const _address = hotel.address || {};
  const _addressString = _address
    ? `${_address.address_line1}+${_address.locality}+${_address.administrative_area}+${_address.postal_code}`
    : "";
  const _addressUrl = _addressString.replace(/\s+/g, "+");
  const _directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${_addressUrl}`;
  const isMobileOnly = useMobileDetect();

  return (
    <Container>
      <Row>
        <TitleContainer>
          <Title2 id="hotel-title">{aboutTitle}</Title2>
          {!isMobileOnly && (
            <AddressBar
              address={_address}
              directionLink={_directionsLink}
              phone={hotel.phone}
              hotelInfoFontColor={"black"}
            />
          )}
          <Time checkInTime={checkInTime} checkOutTime={checkOutTime} />
        </TitleContainer>
        <DescContainer>
          <Description text={aboutText} themeColor={themeColor?.color} />
        </DescContainer>
      </Row>
    </Container>
  );
};

export default About;
