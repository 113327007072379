import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { Constants } from "../../@types/Constants";
import { IHotelPageProps } from "./HotelPageProps";

import DeepLinking from "../../components/common/DeepLinking/DeepLinking";
import HotelTopAlert from "../../components/common/HotelTopAlert/HotelTopAlert";
import PhotosGallery from "../../components/common/PhotosGallery/PhotosGallery";
import VideoPlayer from "../../components/common/VideoPlayer/VideoPlayer";
import Layout from "../../components/global/Layout/Layout";
import SEO from "../../components/global/SEO/SEO";
import Title2 from "../../components/global/Title2/Title2";

import { useAppSelector } from "../../hooks";
import useMobileDetect from "../../hooks/useMobileDetect";
import { useWebFramed } from "../../hooks/useWebFramed";

import { brandSelector } from "../../redux/slices/Brand/brand";
import { searchSelector } from "../../redux/slices/Search/search";

import { gotoTab } from "../../utils/helpers";
import { getParagraph } from "../../utils/paragraphHelpers";
import { Storage } from "../../utils/storage";

import {
  Banner,
  HotelAwardsSection,
  HotelPageBreadCrumb,
  StyledContainer,
} from "./HotelPageHelpers";
import About from "./helperComponents/About/About";
import JsonLd from "../../components/global/JsonLd/JsonLd";
import {
  HelperAddressBar,
  HelperMobileAddressBar,
} from "./helperComponents/AddressBar/AddressBar";
import HotelRoomsSection from "./helperComponents/HotelRoomsSection/HotelRoomsSection";
import ExploreAreaSection from "./helperComponents/ExploreAreaSection/ExploreAreaSection";
import HotelDetailsSection from "./helperComponents/HotelDetailsSection/HotelDetailsSection";
import FeatureModal from "./helperComponents/FeatureModal/FeatureModal";
import useInitializeSearch from "./helperComponents/HotelPageHooks/useInitializeSearch";
import {
  addPageType,
  checkHash,
  checkOffersInPath,
  fetchPointsOfInterest,
  generateAnchorLinks,
  handleBadPathRedirect,
  handleModalClick,
} from "./helperComponents/utils";
import { hotelFragments } from "./helperComponents/queries/hotelPageQueries"; //don't remove this fragment
import { IABTastyWebData } from "../../@types/IABTastyWebData";

const HotelPage = (props: IHotelPageProps): JSX.Element => {
  const isWebFramed = useWebFramed();
  const searchData = useAppSelector(searchSelector);
  const isMobileOnly = useMobileDetect();
  const brand = useAppSelector(brandSelector);
  const _hotel = props.data.hotel;
  const _queriedLinks = props.data.allLocationCity.nodes;
  const unlockBannerShow = props.data.page
    ? props.data.page.field_show_unlock_banner
    : false;
  const _location = props.location;
  const {
    relationships,
    crs_code,
    address = {},
    field_video_url,
    field_default_length_of_stay: hotelDefaultLOS,
    field_default_lead_days: hotelDefaultLD,
    field_lead_start_date: hotelDefaultCheckinDate,
    field_default_adults: hotelDefaultAdults,
    lat_lon,
    features,
    description,
    name: hotelName,
  } = _hotel;
  const {
    brand_id: brandId,
    field_page_message: pageTopAlert,
    hotelAwards = [],
    field_hotel_body,
    offers,
  } = relationships;
  const _featuredOffers = offers
    ? getParagraph(offers, { crsCode: crs_code })
    : null;
  const _fieldBody = field_hotel_body
    ? getParagraph(field_hotel_body, {
        crsCode: crs_code,
      })
    : null;

  const _gallery = Array.isArray(props.pageContext.galleryImages)
    ? props.pageContext.galleryImages
    : [];

  const hotelInfoBrandBarBgColor = brandId?.field_brandbars_bg_color?.color;
  const hotelInfoBrandBarFontColor = brandId?.field_brandbars_fore_color?.color;
  const hotelInfoUrgBgColor = brandId?.field_urgency_msg_bg_color?.color;
  const hotelInfoUrgFontColor = brandId?.field_urgency_msg_fore_color?.color;
  const hotelInfoActiveColor = brandId?.field_brand_stripe_color?.color;
  const countryCode = address?.country_code;
  const [points, setPoints] = useState([]);
  const [showAnchor, setShowAnchor] = useState(false);
  const [loadingPoints, setLoadingPoints] = useState(false);
  const [distance, setDistance] = useState(10);
  const [offerClicked, _setOfferClicked] = useState(false);
  const [_ShowOfferMessage, setShowOfferMessage] = useState(false);
  const [_message, setMessage] = useState({});
  const [tabId, setTabId] = useState<string | null>(null);
  const checkDeepLink = useInitializeSearch({
    _coordinates: lat_lon,
    _hotel,
    _location,
    searchData,
    hotelDefaultLOS,
    hotelDefaultLD,
    hotelDefaultCheckinDate,
    hotelDefaultAdults,
  });
  const hotelParagraphs = relationships.field_sections.map((paragraph) => {
    return getParagraph(paragraph, {
      backgroundColor: hotelInfoBrandBarBgColor,
    });
  });
  const [showModalFeature, setShowModalFeature] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const specialOffers = relationships.offers;
  const renderedFeatures = [];
  let aboutText = "";
  const logo = brand?.relationships?.field_logo?.publicUrl || "";
  if (features !== null && features.length) {
    features.forEach((feature: { tab_title: string; description: string }) => {
      const tabKey = feature.tab_title.toLowerCase().trim();
      if (tabKey === "about") {
        aboutText = feature.description;
      } else {
        renderedFeatures.push(feature);
      }
    });
  }
  const _addressString = address
    ? `${address.address_line1}+${address.locality}+${address.administrative_area}+${address.postal_code}`
    : "";
  const _addressUrl = _addressString.replace(/\s+/g, "+");
  const _directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${_addressUrl}`;
  let _metaDescription = _hotel.field_hotel_meta_description;
  const isTargetcookiesActive =
    typeof window !== "undefined" &&
    window?.OnetrustActiveGroups?.includes("C0004")
      ? true
      : false;
  const BreadcrumbDetails = { _hotel, _queriedLinks, _location };
  if (!_metaDescription) {
    if (_metaDescription && description && description.length > 160) {
      const _subDesc = description.substring(0, 160).split(".");
      _subDesc.pop();
      _metaDescription = _subDesc.join(".") + ".";
    } else {
      _metaDescription = description;
    }
  }
  const _anchorLinks = generateAnchorLinks({
    gallery: _gallery,
    loadingPoints,
    specialOffers: _featuredOffers,
    points,
    features,
  });

  const brandBias = JSON.parse(
    Storage.GetLocalStorageValue(Constants.BRAND_BIAS_KEY)
  );
  if (brandBias?.brand_code !== relationships.brand_id.brand_code) {
    Storage.ClearLocalStorageValue(Constants.BRAND_BIAS_KEY);
  }

  const handleClickTab = (_id: string) => {
    setTabId(_id);
    gotoTab(_id, isMobileOnly);
  };

  const handleSetDistance = (newDistance: any) => {
    setDistance(newDistance);
  };
  useEffect(() => {
    const cancelFetch = fetchPointsOfInterest({
      lat_lon: _hotel.lat_lon,
      distance,
      setLoadingPoints,
      setPoints,
      setShowAnchor,
    });
    return cancelFetch;
  }, [distance]);

  useEffect(() => {
    if (_hotel && _hotel.crs_code) {
      const windowRef = window as Window & { ABTastyWebData?: IABTastyWebData };
      if (
        !windowRef.ABTastyWebData ||
        windowRef?.ABTastyWebData?.hotelId !== _hotel.crs_code
      ) {
        windowRef.ABTastyWebData = {
          hotelId: _hotel.crs_code,
        };
      }
    }
  }, []);

  useEffect(() => addPageType("hotel-detail"), []);
  useEffect(() => checkOffersInPath(_location?.pathname, handleClickTab), []);
  useEffect(() => checkHash(window.location.hash, handleClickTab), []);
  useEffect(() => handleModalClick(setModalContent, setShowModalFeature), []);
  useEffect(() => handleBadPathRedirect(props.params), []);

  let errorTimer: any;
  const handleOnRoomsLoad = (
    offerAvailable: any,
    rate: any,
    discountType = "Promo code"
  ) => {
    clearTimeout(errorTimer);
    let message = {};
    if (!offerAvailable) {
      const discountText = offerClicked ? "Promo code" : "Discount";
      const errorMsg = (
        <>
          {discountText} <strong>{rate}</strong> is not available for the
          current search. Please modify your search criteria above. <br />
          <Link to="#Offers" className="text-underline">
            <u>Select another offer</u>
          </Link>{" "}
          or select a room below to proceed with your booking.
        </>
      );
      message = {
        type: "danger",
        text: errorMsg,
      };
    } else {
      const successMsg = (
        <>
          {discountType} <strong>{rate}</strong> has been applied. Select a room
          below to proceed with your booking.
        </>
      );
      message = {
        type: "success",
        text: successMsg,
      };
    }
    setShowOfferMessage(true);
    setMessage(message);
    const element = document.getElementById("offer-message");
    if (element) {
      element.scrollIntoView(true);
    }
    // auto hide after 15 sec
    errorTimer = setTimeout(() => {
      setShowOfferMessage(false);
      setMessage("");
    }, 10000);
  };
  return (
    <Layout
      brand={relationships.brand_id}
      showLogo
      isHotelPage
      searchBreadCrumbStep="2"
      showMerchandisingBlock={countryCode == "US"}
    >
      <JsonLd
        hotel={_hotel}
        siteUrl={props?.pageContext?.siteData?.siteMetadata?.siteUrl}
      />
      <SEO
        title={_hotel.field_meta_t || hotelName}
        description={_metaDescription}
        includeLeaflet
        crsCode={crs_code}
        city={_hotel?.address?.locality}
        brand={
          relationships && relationships.brand_id && relationships.brand_id.name
        }
        koddiTitle="HotelPage"
        location={props.location}
      />
      <Helmet>
        <script src="https://www.speedrfp.com/irfps/js/widget/"></script>
      </Helmet>
      {checkDeepLink && (
        <DeepLinking
          location={_location}
          leadDays={hotelDefaultLD}
          lengthOfStay={hotelDefaultLOS}
          pageName={"Hotel"}
          HotelCode={crs_code}
          hotelDefaultCheckinDate={hotelDefaultCheckinDate}
        />
      )}
      <Banner
        mobileImage={relationships.mobileImage}
        desktopImage={relationships.desktopImage}
      />
      {!isMobileOnly ? (
        <HelperAddressBar
          hotelName={hotelName}
          hotelInfoBrandBarBgColor={hotelInfoBrandBarBgColor}
          hotelInfoBrandBarFontColor={hotelInfoBrandBarFontColor}
          showAnchor={showAnchor}
          anchorLinks={_anchorLinks}
          tabId={tabId}
          handleClickTab={handleClickTab}
          hotelInfoActiveColor={hotelInfoActiveColor}
        />
      ) : (
        <HelperMobileAddressBar
          logo={logo}
          searchData={searchData}
          hotelName={hotelName}
          address={address}
          hotelInfoBrandBarBgColor={hotelInfoBrandBarBgColor}
          hotelInfoBrandBarFontColor={hotelInfoBrandBarFontColor}
          anchorLinks={_anchorLinks}
          tabId={tabId}
          handleClickTab={handleClickTab}
          hotelInfoActiveColor={hotelInfoActiveColor}
          directionsLink={_directionsLink}
          hotelPhone={_hotel.phone}
        />
      )}
      {pageTopAlert && pageTopAlert.length !== 0 ? (
        <HotelTopAlert
          message={pageTopAlert[0] ? pageTopAlert[0] : ""}
          hotelCode={crs_code || ""}
        />
      ) : null}

      <StyledContainer
        fluid="sm"
        className="aboutModuleContainer hotelGlobalContainer"
      >
        <>
          <a
            className="anchor-target visually-hidden"
            id="overview"
            aria-hidden={true}
          />
          <About hotel={_hotel} />
        </>
      </StyledContainer>
      {hotelParagraphs && hotelParagraphs.length > 0
        ? hotelParagraphs[0]
        : null}
      <HotelRoomsSection
        searchData={searchData}
        hotel={_hotel}
        isWebFramed={isWebFramed}
        handleOnRoomsLoad={handleOnRoomsLoad}
        unlockBannerShow={unlockBannerShow}
        hotelInfoUrgBgColor={hotelInfoUrgBgColor}
        hotelInfoUrgFontColor={hotelInfoUrgFontColor}
      />

      {hotelAwards.length > 0 && <HotelAwardsSection awards={hotelAwards} />}
      {field_video_url && (
        <StyledContainer fluid="sm">
          <Title2 className="explore-sonesta">Explore Sonesta</Title2>
        </StyledContainer>
      )}
      {field_video_url && isTargetcookiesActive && (
        <StyledContainer fluid="sm" className="hotelGlobalContainer">
          <VideoPlayer
            url={field_video_url.url}
            width="100%"
            height="100%"
            aria-hidden="true"
          />
        </StyledContainer>
      )}

      {_gallery?.length && (
        <StyledContainer fluid="sm" className="hotelGlobalContainer">
          <div>
            <a id="gallery" aria-hidden={true} />
            <PhotosGallery
              photos={_gallery}
              imgCountPerRow={1}
              subtitle="TAKE A VISUAL TOUR"
              newCarousel={true}
            />
          </div>
        </StyledContainer>
      )}
      {true && (_featuredOffers || specialOffers) && (
        <StyledContainer className="bg-light hotelGlobalContainer" fluid={true}>
          <a
            className="anchor-target visually-hidden"
            id="offers"
            aria-hidden={true}
          />
          <>{_featuredOffers}</>
        </StyledContainer>
      )}
      <ExploreAreaSection
        coordinates={lat_lon}
        distance={distance}
        points={points}
        handleSetDistance={handleSetDistance}
        loadingPoints={loadingPoints}
        hotel={_hotel}
      />
      <HotelDetailsSection
        features={features}
        renderedFeatures={renderedFeatures}
        isWebFramed={isWebFramed}
        hotel={_hotel}
        location={_location}
      />

      {_fieldBody && <StyledContainer>{_fieldBody}</StyledContainer>}
      {!isWebFramed && (
        <HotelPageBreadCrumb BreadcrumbDetails={BreadcrumbDetails} />
      )}
      <FeatureModal
        showModalFeature={showModalFeature}
        setShowModalFeature={() => setShowModalFeature(false)}
        modalContent={modalContent}
      />
    </Layout>
  );
};
export const query = graphql`
  query ($slug: String!, $pathRegex: String) {
    hotel(path: { alias: { eq: $slug } }) {
      ...HotelFields
    }
    page: nodePage(title: { eq: "Hotel Search" }) {
      id
      field_show_unlock_banner
      field_show_special_offers
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphMediaCta
        }
      }
    }
    allLocationCity(
      filter: { fields: { path: { alias: { regex: $pathRegex } } } }
    ) {
      nodes {
        name
        fields {
          path {
            alias
            parent_alias
          }
        }
        relationships {
          field_sites {
            machine_name
          }
        }
      }
    }
  }
`;
export default HotelPage;
