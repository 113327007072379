// extracted by mini-css-extract-plugin
export var addressBar = "HotelPage-module--addressBar--b4644";
export var addressBarContainer = "HotelPage-module--addressBarContainer--82685";
export var alt = "HotelPage-module--alt--9af53";
export var anchorMenuContainer = "HotelPage-module--anchorMenuContainer--11680";
export var awardsContainer = "HotelPage-module--awardsContainer--30d23";
export var banner = "HotelPage-module--banner--ec269";
export var breadCrumbCss = "HotelPage-module--breadCrumbCss--3352d";
export var container = "HotelPage-module--container--7d03e";
export var contentLeftAlign = "HotelPage-module--contentLeftAlign--e2cf0";
export var customSticky = "HotelPage-module--customSticky--ef03e";
export var desktopOnly = "HotelPage-module--desktopOnly--db49c";
export var hotelTitle = "HotelPage-module--hotelTitle--93c44";
export var main = "HotelPage-module--main--088d3";
export var menuItemActive = "HotelPage-module--menuItemActive--1472c";
export var menuNav = "HotelPage-module--menuNav--6444a";
export var menuNavItem = "HotelPage-module--menuNavItem--d8c38";
export var mobileMenuContainer = "HotelPage-module--mobileMenuContainer--e6d17";
export var mobileOnly = "HotelPage-module--mobileOnly--401e7";
export var mobilelAddressBar = "HotelPage-module--mobilelAddressBar--525f9";
export var navLink = "HotelPage-module--navLink--ee490";
export var phone = "HotelPage-module--phone--38cf8";
export var stickyContainer = "HotelPage-module--stickyContainer--afde7";
export var textLeft = "HotelPage-module--textLeft--93c93";
export var underLineText = "HotelPage-module--underLineText--d3145";
export var visuallyHidden = "HotelPage-module--visuallyHidden--df9fc";