import React from "react";
import { Collapse, Row, Col, Button } from "react-bootstrap";
import AccessibleFilter from "../../AccessibleFilter/AccessibleFilter";
import { RoomFiltersProps } from "../HotelRoomsHelpersProps";
import RoomTypeFilter from "../../RoomTypeFilter/RoomTypeFilter";
import {
  FilterTabsContainer,
  AccessibleFilterCol,
} from "../HotelRoomComponents";
import { RoomSortComponent } from "./RoomSortComponent";

const RoomFilters: React.FC<RoomFiltersProps> = ({
  openFilter,
  background,
  showRoomTypeFilter,
  sortOrder,
  handleSortChange,
  setSortOrderValue,
  sortOrderValue,
  accessibleFilter,
  setAccessibleFilter,
  filterRoomType,
  setFilterRoomType,
  handleTogleMobileFilter,
  isPromoValid,
  isMobileOnly,
}) => (
  <>
    <Collapse in={openFilter} className="d-lg-block">
      <div
        id="roomFilters"
        className="room-filters-container mb-4"
        style={{
          backgroundColor: background || "#f8f8f8",
          whiteSpace: "nowrap",
        }}
      >
        <Row className="search-filters g-0" id="search-filters">
          <Col
            lg={{ span: 3 }}
            className={`text-left mt-3 mt-lg-0 ${openFilter && "mb-4"}`}
          >
            <RoomSortComponent
              sortOrder={sortOrder}
              handleSortChange={handleSortChange}
              setSortOrderValue={setSortOrderValue}
              sortOrderValue={sortOrderValue}
            />
          </Col>
          <Col xs={6} lg={{ span: 2 }} className="my-auto mb-3 mb-lg-0">
            {/* {isPromoValid ? <PromoFilter /> : null} */}
          </Col>
          <FilterTabsContainer>
            <Row>
              {showRoomTypeFilter ? (
                <>
                  <Col lg={6}>
                    <AccessibleFilter
                      accessibleFilter={accessibleFilter}
                      setAccessibleFilter={setAccessibleFilter}
                    />
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <RoomTypeFilter
                      setFilter={setFilterRoomType}
                      filterRoomType={filterRoomType}
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col lg={12}>
                    <div className="d-flex justify-content-end">
                      <AccessibleFilter
                        accessibleFilter={accessibleFilter}
                        setAccessibleFilter={setAccessibleFilter}
                      />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </FilterTabsContainer>
        </Row>
        <Button
          className="close-filters w-100 d-lg-none mt-3"
          variant="secondary"
          onClick={handleTogleMobileFilter}
        >
          Close
        </Button>
      </div>
    </Collapse>
    {isMobileOnly ? (
      showRoomTypeFilter ? (
        <>
          <Row className="g-0">
            <Col xs={6} className="text-left mt-lg-0">
              <RoomSortComponent
                sortOrder={sortOrder}
                handleSortChange={handleSortChange}
                setSortOrderValue={setSortOrderValue}
                sortOrderValue={sortOrderValue}
              />
            </Col>
            {isPromoValid ? (
              <Col xs={6} className="my-auto mb-3 mb-lg-0">
                {/* <PromoFilter /> */}
              </Col>
            ) : null}
          </Row>
          <hr style={{ marginTop: "20px" }} />
          <Row className="mb-4">
            <Col lg={6} xs={7}>
              <AccessibleFilter
                accessibleFilter={accessibleFilter}
                setAccessibleFilter={setAccessibleFilter}
              />
            </Col>
            <Col lg={6} xs={5} className="text-right">
              <RoomTypeFilter
                setFilter={setFilterRoomType}
                filterRoomType={filterRoomType}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          {isPromoValid ? (
            <Row className="g-0">
              <Col xs={6} className="my-auto mb-3 mb-lg-0">
                {/* <PromoFilter /> */}
              </Col>
              <hr style={{ marginTop: "10px" }} />
            </Row>
          ) : null}
          <Row className="mb-4">
            <Col xs={6} style={{ width: "auto !important" }}>
              <RoomSortComponent
                sortOrder={sortOrder}
                handleSortChange={handleSortChange}
                setSortOrderValue={setSortOrderValue}
                sortOrderValue={sortOrderValue}
              />
            </Col>
            <AccessibleFilterCol>
              <AccessibleFilter
                accessibleFilter={accessibleFilter}
                setAccessibleFilter={setAccessibleFilter}
              />
            </AccessibleFilterCol>
          </Row>
        </>
      )
    ) : null}
  </>
);

export default RoomFilters;
