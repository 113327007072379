import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { RoomListProps } from "../HotelRoomsHelpersProps";
import RoomItem from "./RoomItemComponent";

export const RoomList: React.FC<RoomListProps> = ({
  rooms,
  isMobileOnly,
  totalVisibleRoom,
  handleSelectRoom,
  setTotalVisibleRoom,
  updatingRates,
  isGroupCodeValid,
  hotel,
  hotelInfoUrgBgColor,
  hotelInfoUrgFontColor,
  redemptionItem,
}) => (
  <Row lg={3} xs={1}>
    {isMobileOnly
      ? rooms
          .slice(0, totalVisibleRoom)
          .map((room, idx) => (
            <RoomItem
              key={idx}
              room={room}
              idx={idx}
              handleSelectRoom={handleSelectRoom}
              updatingRates={updatingRates}
              isGroupCodeValid={isGroupCodeValid}
              hotel={hotel}
              hotelInfoUrgBgColor={hotelInfoUrgBgColor}
              hotelInfoUrgFontColor={hotelInfoUrgFontColor}
              redemptionItem={redemptionItem}
              isMobileOnly={isMobileOnly}
            />
          ))
      : rooms.map((room, idx) => (
          <RoomItem
            key={idx}
            room={room}
            idx={idx}
            handleSelectRoom={handleSelectRoom}
            updatingRates={updatingRates}
            isGroupCodeValid={isGroupCodeValid}
            hotel={hotel}
            hotelInfoUrgBgColor={hotelInfoUrgBgColor}
            hotelInfoUrgFontColor={hotelInfoUrgFontColor}
            redemptionItem={redemptionItem}
            isMobileOnly={isMobileOnly}
          />
        ))}
    {isMobileOnly && rooms.length && totalVisibleRoom < rooms.length ? (
      <Col className="mb-4">
        <Button
          className="w-100 text-uppercase show-all-select-room"
          id="show-all-rooms-button"
          size="lg"
          onClick={() => setTotalVisibleRoom(rooms.length)}
        >
          Show all {rooms.length} Results &nbsp;
          <FontAwesomeIcon icon="chevron-down" className="drop-down-icon" />
        </Button>
      </Col>
    ) : null}
  </Row>
);
