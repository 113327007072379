// ExploreAreaSection.tsx
import React from "react";
import { Col, Row } from "react-bootstrap";
import IHotel from "../../../../@types/IHotel";
import { StyledContainer } from "../../HotelPageHelpers";
import { Subtitle } from "../../../../pageHelpers/Contact/ContactHelperComponents";
import Title2 from "../../../../components/global/Title2/Title2";
import PointsOfInterest from "../../../../components/common/PointsOfInterest/PointsOfInterest";

interface ExploreAreaSectionProps {
  coordinates: {
    lat: number;
    lon: number;
  } | null;
  distance: number;
  points: any[];
  handleSetDistance: (newDistance: number) => void;
  loadingPoints: boolean;
  hotel: IHotel;
}

const ExploreAreaSection: React.FC<ExploreAreaSectionProps> = ({
  coordinates,
  distance,
  points,
  handleSetDistance,
  loadingPoints,
  hotel,
}) => {
  if (!coordinates || (distance > 75 && (distance !== 100 || !points.length))) {
    return null;
  }

  return (
    <StyledContainer
      fluid="sm"
      className="pt-4 pt-lg-5 pb-4 pb-lg-5 position-relative hotelGlobalContainer"
    >
      <>
        <a
          className="anchor-target visually-hidden"
          id="thingstodo"
          aria-hidden={true}
        />
        <Row>
          <Col className="text-center">
            <Subtitle>Explore the Area</Subtitle>
            <Title2 id="what-nearby">What&apos;s Nearby</Title2>
          </Col>
        </Row>
        <Row aria-hidden="true">
          <Col>
            <PointsOfInterest
              latitude={coordinates.lat}
              longitude={coordinates.lon}
              points={points}
              setDistance={handleSetDistance}
              distance={distance}
              isLoading={loadingPoints}
              poiLocation={hotel}
              type={"hotel"}
            />
          </Col>
        </Row>
      </>
    </StyledContainer>
  );
};

export default ExploreAreaSection;
