import * as React from "react";
import { container, togglerSwitch } from "./AccessibleFilter.module.scss";

export const Container: React.FC<{ children: React.ReactChild }> = (props) => {
  return <div className={container}>{props.children}</div>;
};
export const ToggleSwitch: React.FC<{ children: React.ReactChild }> = (
  props
) => {
  return (
    <label htmlFor="accessibleRooms" className={togglerSwitch}>
      {props.children}
    </label>
  );
};
