import { useEffect } from "react";
import IRoom from "../../../../../@types/IRoom";
import { IRoomRates, IHotelRoomsProps } from "../../HotelRoomsProps";

const useFilterRooms = (
  roomsLoaded: boolean,
  loadingRates: boolean,
  roomRatesParsed: boolean,
  allRooms: any,
  _roomRates: IRoomRates[] | null,
  setFilteredRooms: React.Dispatch<React.SetStateAction<IRoom[] | null>>,
  setFilteredRoomsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  accessibleFilter: boolean,
  setAccessibleFilter: React.Dispatch<React.SetStateAction<boolean>>,
  filterRoomType: string,
  filterOccupancy: string,
  sortOrderValue: string,
  sortOrder: string,
  roomsToDisplay: IRoom[],
  search: any,
  checkout: any,
  props: IHotelRoomsProps,
  addPropertyRoomsGTMDataLayer: any
): void => {
  useEffect(() => {
    if (!loadingRates && roomsLoaded && roomRatesParsed && allRooms) {
      if (roomsToDisplay && roomsToDisplay.length) {
        addPropertyRoomsGTMDataLayer(
          props.hotel,
          roomsToDisplay,
          search,
          checkout
        );
        let filterRoomsByAccessible = roomsToDisplay.filter(
          (room) => room.accessible === accessibleFilter
        );
        if (!filterRoomsByAccessible.length) {
          filterRoomsByAccessible = roomsToDisplay.filter(
            (room) =>
              room.Accessible === true || room.name.includes("Accessible")
          );
          setAccessibleFilter(true);
        }
        setFilteredRooms(filterRoomsByAccessible);
        setFilteredRoomsLoaded(true);
      } else {
        setFilteredRooms([]); // Explicitly set to empty array when no rooms available
        setFilteredRoomsLoaded(true);
      }
    }
  }, [roomsToDisplay, allRooms, loadingRates, roomRatesParsed]);

  useEffect(() => {
    let roomArray = [...roomsToDisplay];
    if (roomArray && roomArray.length) {
      roomArray = roomArray.filter((room) => {
        return room.quantity > 0 && room.roomRates;
      });

      if (filterRoomType) {
        roomArray = roomArray.filter((room) => {
          return room.RoomType?.length === 0 ||
            room.RoomType?.includes(null) ||
            !["Suites", "Rooms"].some((element) =>
              room.RoomType?.includes(element)
            )
            ? true
            : room.RoomType && room.RoomType.includes(filterRoomType);
        });
      }

      const filterAccessibleRooms = (fieldValue: boolean) =>
        roomArray.filter((room) => {
          return room.accessible === fieldValue;
        });

      if (!accessibleFilter) {
        const filterByAccessible = filterAccessibleRooms(false);
        roomArray = [...filterByAccessible];
      } else {
        setAccessibleFilter(true);
      }

      if (filterOccupancy) {
        roomArray = roomArray.filter((room) => {
          return room.occupancy == filterOccupancy;
        });
      }
    }
    if (sortOrderValue === "Promotional Price") {
      const promoRooms = roomArray.filter((room) => room.FromRatePromotional);
      const remainingRooms = roomArray.filter(
        (room) => !room.FromRatePromotional
      );
      promoRooms.sort((a, b) => {
        const aRate =
          a.BaseRate !== null && a.BaseRate !== undefined
            ? a.BaseRate
            : a.FromRate;
        const bRate =
          b.BaseRate !== null && b.BaseRate !== undefined
            ? b.BaseRate
            : b.FromRate;
        return sortOrder === "asc" ? aRate - bRate : bRate - aRate;
      });

      remainingRooms.sort((a, b) => {
        const aRate =
          a.BaseRate !== null && a.BaseRate !== undefined
            ? a.BaseRate
            : a.FromRate;
        const bRate =
          b.BaseRate !== null && b.BaseRate !== undefined
            ? b.BaseRate
            : b.FromRate;

        return sortOrder === "asc"
          ? aRate - b.BaseRate
          : b.BaseRate - a.BaseRate;
      });

      roomArray = [...promoRooms, ...remainingRooms];
    } else if (sortOrderValue === "Lowest Price") {
      roomArray.sort((a, b) =>
        sortOrder === "asc" ? a.BaseRate - b.BaseRate : b.BaseRate - a.BaseRate
      );
    } else if (sortOrderValue === "Highest Price") {
      roomArray.sort((a, b) =>
        sortOrder === "asc" ? b.BaseRate - a.BaseRate : a.BaseRate - b.BaseRate
      );
    }
    setFilteredRooms(roomArray);
  }, [
    filterRoomType,
    filterOccupancy,
    sortOrder,
    accessibleFilter,
    roomsToDisplay,
    sortOrderValue,
  ]);
};

export default useFilterRooms;
