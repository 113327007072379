import IHotel from "../../../@types/IHotel";
import { IJsonLdProps } from "./JsonLdProps";

const CONTEXT = "https://schema.org";
const PARENT_ORGANIZATION = "Sonesta International Hotels Corporation";
const MAP_URL = "https://www.google.com/maps/place/";

export const getHotelJson = (hotel: IHotel, siteUrl: string): IJsonLdProps => {
  const image =
    Array.isArray(hotel.gallery_images) && hotel.gallery_images.length > 0
      ? hotel.gallery_images[0].url
      : "";
  return {
    "@context": CONTEXT,
    "@type": "Hotel",
    checkinTime: hotel.field_check_in || "",
    checkoutTime: hotel.field_check_out || "",
    url: `${siteUrl}${hotel.path?.alias}`,
    name: hotel.name,
    address: {
      "@type": "PostalAddress",
      "@id": hotel?.crs_code || "",
      addressCountry: hotel?.address?.country_code,
      addressLocality: hotel?.address?.locality,
      addressRegion: hotel?.address?.administrative_area,
      streetAddress: hotel?.address?.address_line1,
      postalCode: hotel?.address?.postal_code,
      telephone: hotel.phone,
    },
    // numberOfRooms: hotel.field_number_of_rooms, //Once we have the appropriate data in core, this field will be utilized in the future.
    longitude: hotel.lat_lon?.lon,
    latitude: hotel.lat_lon?.lat,
    image,
    hasMap: `${MAP_URL}?q=place_id:${hotel.field_googl}`,
    priceRange: hotel.field_price_range,
    telephone: hotel.phone,
    petsAllowed: hotel.amenities?.some(
      (amenity) => amenity.name === "Pet Friendly"
    )
      ? "true"
      : "false",
    description: hotel.description,
    parentOrganization: {
      name: PARENT_ORGANIZATION,
    },
    brand: {
      name: hotel?.relationships.brand_id?.name,
    },
  };
};

export const getPostalAddressJson = (hotel: IHotel) => {
  return {
    "@context": CONTEXT,
    "@type": "PostalAddress",
    "@id": hotel?.crs_code || "",
    addressCountry: hotel?.address?.country_code,
    addressLocality: hotel?.address?.locality,
    addressRegion: hotel?.address?.administrative_area,
    streetAddress: hotel?.address?.address_line1,
    postalCode: hotel?.address?.postal_code,
    telephone: hotel.phone,
    name: hotel.name,
  };
};
