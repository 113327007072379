import { useEffect, useRef } from "react";
import IRoom from "../../../../../@types/IRoom";
import { addRatesASE } from "../../../../../redux/slices/Rate/rate";
import { buildFetchRatesUrl } from "../../../../../services/rates";
import { IHotelRoomsProps } from "../../HotelRoomsProps";

export const useFetchRates = (
  props: IHotelRoomsProps,
  search: any,
  dispatch: any,
  setFireEvent: React.Dispatch<React.SetStateAction<boolean>>,
  setLoadingRates: React.Dispatch<React.SetStateAction<boolean>>,
  setRoomsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  setUpdatingRates: React.Dispatch<React.SetStateAction<boolean>>,
  setMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsPromoValid: React.Dispatch<React.SetStateAction<boolean>>,
  setShowOfferMessage: React.Dispatch<React.SetStateAction<boolean>>,
  setFilteredRoomsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  setFilteredRooms: React.Dispatch<React.SetStateAction<IRoom[] | null>>,
  setRoomsToDisplay: React.Dispatch<React.SetStateAction<IRoom[]>>,
  setPricingInitialized: React.Dispatch<React.SetStateAction<boolean>>,
  pricingInitialized: boolean,
  isLoggedIn: boolean
): void => {
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchRates = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const apiUrl = buildFetchRatesUrl(
      props.hotel.crs_name || "",
      props.hotel.crs_code || "",
      search.checkin,
      search.checkout,
      search.rooms[0].adults,
      search.rooms[0].children,
      search.promotionCode,
      search.groupCode,
      null,
      search.rooms[0].childrenAges,
      search.ratePlanFilterCode,
      search.discount
    );

    await dispatch(
      addRatesASE(
        apiUrl,
        search.rooms[0].adults,
        search.rooms[0].children,
        search.rooms[0].childrenAges,
        props.hotel.crs_code,
        props.hotel.crs_chain_id,
        search.checkin,
        search.checkout,
        "",
        search.promotionCode,
        search.groupCode,
        search.discount || search.ratePlanFilterCode || "Retail",
        abortController
      )
    );
  };

  const debouncedFetchRates = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      setLoadingRates(true);
      setRoomsLoaded(false);
      setMessage("");
      setIsPromoValid(false);
      setShowOfferMessage(false);
      setFilteredRoomsLoaded(false);
      setFilteredRooms(null); // Set to null between calls
      await fetchRates();
      setFireEvent(true);
      setLoadingRates(false);
      if (!pricingInitialized) {
        setPricingInitialized(true);
      }
    }, 250); //  debounce time
  };

  useEffect(() => {
    debouncedFetchRates();

    const intervalId = setInterval(async () => {
      setUpdatingRates(true);
      setShowOfferMessage(false);

      await fetchRates();
      setUpdatingRates(false);
    }, 300000);

    return () => {
      clearInterval(intervalId);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [search, isLoggedIn]);
};

export default useFetchRates;
