// useInitializeSearch.ts
import { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ISearchPayload } from "../../../../@types/ISearchPayload";
import { getSearchPayloadStayDates } from "../../../../utils/helpers";
import { Room } from "../../../../models/room";
import { setSearch } from "../../../../redux/slices/Search/search";

interface Coordinates {
  lat: number;
  lon: number;
}

interface Location {
  pathname: string;
  state?: {
    defaultSearch?: boolean;
  };
}

interface UseInitializeSearchProps {
  _coordinates: Coordinates;
  _hotel: {
    name: string;
    crs_code: string;
    relationships: {
      brand_id: {
        brand_code: string;
      };
    };
  };
  _location: Location;
  searchData: {
    customUrl?: string;
  };
  hotelDefaultLOS: number;
  hotelDefaultLD: number;
  hotelDefaultCheckinDate: string;
  hotelDefaultAdults: number;
}

const useInitializeSearch = ({
  _coordinates,
  _hotel,
  _location,
  searchData,
  hotelDefaultLOS,
  hotelDefaultLD,
  hotelDefaultCheckinDate,
  hotelDefaultAdults,
}: UseInitializeSearchProps) => {
  const dispatch = useDispatch();
  const [checkDeepLink, setCheckDeepLink] = useState(false);

  useLayoutEffect(() => {
    const payload: ISearchPayload = {
      latitude: _coordinates.lat,
      longitude: _coordinates.lon,
      searchString: _hotel.name,
      searchType: "hotel",
      customUrl: _location?.pathname || searchData?.customUrl || "",
      searchTypeGapi: "lodging",
    };
    if (!_location?.state || _location.state?.defaultSearch) {
      window.history.replaceState(
        { ..._location.state, defaultSearch: false },
        ""
      ); // so that these default values are not used again on reload of page
      const searchString =
        typeof location?.search === "string" ? location.search : "";
      let searchParams: URLSearchParams;

      try {
        searchParams = new URLSearchParams(searchString);
      } catch (error) {
        console.error("Invalid search string:", searchString, error);
        searchParams = new URLSearchParams(); // Fallback to an empty URLSearchParams object
      }

      const queryParams: Record<string, string> =
        Object.fromEntries(searchParams);
      const queryParamsLowercase = Object.keys(queryParams).reduce(
        (qp, key) => {
          if (typeof key === "string" && typeof queryParams[key] === "string") {
            qp[key.toLowerCase()] = queryParams[key];
          }
          return qp;
        },
        {} as Record<string, string>
      );

      const hasCheckinCheckoutParams =
        "checkin" in queryParamsLowercase && "checkout" in queryParamsLowercase;
      if (!hasCheckinCheckoutParams) {
        const { formedCheckin, formedCheckout } = getSearchPayloadStayDates(
          null,
          null,
          hotelDefaultLOS,
          hotelDefaultLD,
          hotelDefaultCheckinDate
        );
        if (formedCheckin && formedCheckout) {
          payload.checkin = formedCheckin;
          payload.checkout = formedCheckout;
        }
      }
      if (hotelDefaultAdults) {
        payload.rooms = [new Room(hotelDefaultAdults)];
      }
    }
    dispatch(setSearch(payload));
    setCheckDeepLink(true);
  }, []);

  return checkDeepLink;
};

export default useInitializeSearch;
