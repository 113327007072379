import { guestServiceProvider } from "../../../services/ServiceProvider";
import { addPageTypeInGTMDataLayer } from "../../../utils/datalayers";
import Axios from "axios";

export const addPageType = (type: string) => {
  try {
    addPageTypeInGTMDataLayer(type);
  } catch (err) {
    console.error(err);
  }
};

export const checkOffersInPath = (
  pathname: string | undefined,
  handleClickTab: (tabId: string) => void
) => {
  try {
    const path = pathname?.split("/");
    const pathLastPart = path ? path.pop() || path.pop() : ""; // twice pop to handle if trailing slash is there
    if (pathLastPart === "offers") {
      handleClickTab("Offers");
    }
  } catch (err) {
    console.error(err);
  }
};

export const checkHash = (
  hash: string,
  handleClickTab: (tabId: string) => void
) => {
  try {
    if (hash === "#rooms") {
      handleClickTab("rooms");
    }
  } catch (error) {
    console.error(error);
  }
};

export const handleModalClick = (
  setModalContent: React.Dispatch<React.SetStateAction<string>>,
  setShowModalFeature: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const modals = document.querySelectorAll("[data-modal]");

  const handleOpenModal = (event: Event) => {
    event.preventDefault();
    let anchor = event.target as HTMLAnchorElement;

    if (anchor.tagName !== "A") {
      anchor = anchor.parentElement as HTMLAnchorElement;
    }

    setModalContent(anchor.href);
    setShowModalFeature(true);
  };

  modals.forEach((modal) => modal.addEventListener("click", handleOpenModal));

  return () => {
    modals.forEach((modal) =>
      modal.removeEventListener("click", handleOpenModal)
    );
  };
};

export const handleBadPathRedirect = (params: any) => {
  const asteriskParam = params?.["*"];
  if (asteriskParam) {
    const pathURI = `/${asteriskParam}`;
    const newUrl = window.location.href.replace(pathURI, "");
    window.location.replace(newUrl);
  }
};

interface FetchPointsOfInterestProps {
  lat_lon: { lat: number; lon: number };
  distance: number;
  setLoadingPoints: React.Dispatch<React.SetStateAction<boolean>>;
  setPoints: React.Dispatch<React.SetStateAction<never[]>>;
  setShowAnchor: React.Dispatch<React.SetStateAction<boolean>>;
}

export const fetchPointsOfInterest = ({
  lat_lon,
  distance,
  setLoadingPoints,
  setPoints,
  setShowAnchor,
}: FetchPointsOfInterestProps) => {
  const source = Axios.CancelToken.source();
  let didCancel = false;

  const fetchData = async () => {
    if (!didCancel && lat_lon !== null) {
      setLoadingPoints(true);
      let url = `/location-proximity?coordinates[value]=${distance}`;
      url += `&coordinates[source_configuration][origin][lat]=${lat_lon.lat}&coordinates[source_configuration][origin][lon]=${lat_lon.lon}`;
      url += "&type[0]=point_of_interest&type[1]=airport";
      const guestService = await guestServiceProvider();
      const response = await guestService.getLocationProximity(
        distance,
        lat_lon.lat,
        lat_lon.lon,
        { cancelToken: source.token }
      );

      if (response?.searchLocations?.results) {
        const filteredPoints = response.searchLocations.results.filter(
          (location) =>
            location.locationType === "point_of_interest" ||
            location.locationType === "airport"
        );
        setPoints(filteredPoints);
        setLoadingPoints(false);
        setShowAnchor(true);
      }
    }
  };

  fetchData();

  return () => {
    didCancel = true;
    source.cancel("Request cancelled for points of interest.");
  };
};

interface AnchorLink {
  label: string;
  anchor: string;
}
interface GenerateAnchorLinksProps {
  gallery: any[];
  loadingPoints: boolean;
  specialOffers: any;
  points: any[];
  features: any[];
}
export const generateAnchorLinks = ({
  gallery,
  loadingPoints,
  specialOffers,
  points,
  features,
}: GenerateAnchorLinksProps): AnchorLink[] => {
  const anchorLinks: AnchorLink[] = [{ label: "Overview", anchor: "overview" }];

  anchorLinks.push({ label: "Rooms", anchor: "rooms" });

  if (gallery?.length && !loadingPoints) {
    anchorLinks.push({ label: "Gallery", anchor: "gallery" });
  }
  if (specialOffers && !loadingPoints) {
    anchorLinks.push({ label: "Offers", anchor: "offers" });
  }
  if (points !== null && points?.length && !loadingPoints) {
    anchorLinks.push({ label: "What's Nearby", anchor: "thingstodo" });
  }
  if (features !== null && features.length && !loadingPoints) {
    anchorLinks.push({ label: "Hotel Details", anchor: "hoteldetails" });
  }

  return anchorLinks;
};
