import React from "react";
import OccupancyMessage from "../../OccupancyMessage/OccupancyMessage";
import { FilteredRoomsProps } from "./HotelRoomsHelpersProps";
import { RoomList } from "./RoomList/RoomList";
import OfferMessage from "./OfferMessage";

const FilteredRooms: React.FC<FilteredRoomsProps> = ({
  filteredRooms,
  isMobileOnly,
  totalVisibleRoom,
  handleSelectRoom,
  setTotalVisibleRoom,
  updatingRates,
  isGroupCodeValid,
  hotel,
  hotelInfoUrgBgColor,
  hotelInfoUrgFontColor,
  redemptionItem,
  showOfferMessage,
  message,
  setShowOfferMessage,
  search,
}) => (
  <>
    {filteredRooms && filteredRooms.length > 0 && (
      <div className="hotel-rooms-container">
        <OccupancyMessage
          page="selectroom"
          crsCode={hotel.crs_code || ""}
          roomIndex={0}
        />
        <OfferMessage
          showOfferMessage={showOfferMessage}
          message={message}
          setShowOfferMessage={setShowOfferMessage}
          search={search}
        />
        <RoomList
          rooms={filteredRooms}
          isMobileOnly={isMobileOnly}
          totalVisibleRoom={totalVisibleRoom}
          handleSelectRoom={handleSelectRoom}
          setTotalVisibleRoom={setTotalVisibleRoom}
          updatingRates={updatingRates}
          isGroupCodeValid={isGroupCodeValid}
          hotel={hotel}
          hotelInfoUrgBgColor={hotelInfoUrgBgColor}
          hotelInfoUrgFontColor={hotelInfoUrgFontColor}
          redemptionItem={redemptionItem}
        />
      </div>
    )}
  </>
);

export default FilteredRooms;
