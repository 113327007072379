import React from "react";
import {
  container,
  disclaimer,
  styledContainer,
  subtitle,
  title1,
  title3,
} from "./Contact.module.scss";

export const Container = (props: { children: React.ReactNode }) => {
  return <div className={container}>{props.children}</div>;
};
export const Title1 = (props: {
  children: React.ReactNode;
  className?: string;
  id?: string;
}) => {
  return (
    <h1 className={`${title1} ${props.className}`} id={props.id}>
      {props.children}
    </h1>
  );
};
export const Title3 = (props: {
  children: React.ReactNode;
  className: string;
}) => {
  return <div className={title3}>{props.children}</div>;
};
export const Subtitle = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`${subtitle} ${props.className}`}>{props.children}</div>
  );
};
export const StyledContainer = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`${styledContainer} ${props.className}`}>
      {props.children}
    </div>
  );
};

export const StyledContainerRedlion = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`${styledContainer} ${props.className}`}>
      {props.children}
    </div>
  );
};
export const Disclaimer = (props: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={disclaimer}>{props.children}</div>;
};
