// FeatureModal.tsx
import React from "react";
import { Modal } from "react-bootstrap";

interface FeatureModalProps {
  showModalFeature: boolean;
  setShowModalFeature: (show: boolean) => void;
  modalContent: string;
}

const FeatureModal: React.FC<FeatureModalProps> = ({
  showModalFeature,
  setShowModalFeature,
  modalContent,
}) => (
  <Modal
    size="lg"
    show={showModalFeature}
    onHide={() => setShowModalFeature(false)}
    centered
    scrollable={true}
    aria-labelledby="modal-content"
  >
    <Modal.Header closeButton>
      <Modal.Title></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <iframe src={modalContent} width="100%" height="500" />
    </Modal.Body>
  </Modal>
);

export default FeatureModal;
