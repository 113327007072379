import React from "react";
import { IAccessibleFilterProps } from "./AccessibleFilterProps";
import { Container, ToggleSwitch } from "./AccessibleFilterHelpers";

const AccessibleFilter: React.FC<IAccessibleFilterProps> = (props) => {
  const changeToggle = () => props.setAccessibleFilter(!props.accessibleFilter);
  return (
    <Container>
      <>
        <span className="prefLabel">Accessible Rooms</span>
        <ToggleSwitch>
          <>
            <input
              type="checkbox"
              id="accessibleRooms"
              checked={props.accessibleFilter}
              onChange={changeToggle}
              aria-label="Filter Accessible Rooms"
            />
            <span className="slider round" />
          </>
        </ToggleSwitch>
      </>
    </Container>
  );
};

export default AccessibleFilter;
