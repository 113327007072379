// HotelDetailsSection.tsx
import React from "react";
import { Col, Row } from "react-bootstrap";
import IHotel from "../../../../@types/IHotel";
import ILocation from "../../../../@types/ILocation";
import { StyledContainer } from "../../HotelPageHelpers";
import Title2 from "../../../../components/global/Title2/Title2";
import FeaturesTabs from "../../../../components/common/FeaturesTabs/FeaturesTabs";

interface HotelDetailsSectionProps {
  features: any[];
  renderedFeatures: any[];
  isWebFramed: boolean;
  hotel: IHotel;
  location: ILocation;
}

const HotelDetailsSection: React.FC<HotelDetailsSectionProps> = ({
  features,
  renderedFeatures,
  isWebFramed,
  hotel,
  location,
}) => {
  if (features === null || !renderedFeatures.length) {
    return null;
  }

  return (
    <StyledContainer
      className={`bg-light pt-4 pt-lg-5 position-relative hotelGlobalContainer ${
        isWebFramed ? "mb-4 mb-lg-5 pb-4 pb-lg-5" : ""
      }`}
      fluid={true}
    >
      <>
        <a
          className="anchor-target visually-hidden"
          id="hoteldetails"
          aria-hidden={true}
        />
        <StyledContainer fluid="sm">
          <>
            <Row>
              <Col className="text-center text-lg-start featureCol">
                <div id={"about-the-hotel"} className="subTitle">
                  About The Hotel
                </div>
                <Title2 id="hotel-details-title">Hotel Details </Title2>
              </Col>
            </Row>
            <FeaturesTabs
              features={renderedFeatures}
              gallery={null}
              hotel={hotel}
              location={location}
            />
          </>
        </StyledContainer>
      </>
    </StyledContainer>
  );
};

export default HotelDetailsSection;
