import React from "react";
import { Button, Col, Spinner } from "react-bootstrap";
import Sticky from "react-sticky-el";
import {
  container,
  mobileFilterToggle,
  filterTabsContainer,
  accessibleFilterCol,
} from "../HotelRooms.module.scss";

export const Container: React.FC<{ children: React.ReactNode }> = (props) => {
  return <div className={container}>{props.children}</div>;
};

export const MobileFilterToggle: React.FC<{
  children: React.ReactNode;
  openFilter?: boolean;
  onClick: () => void;
}> = (props) => {
  return (
    <Button
      className={`${mobileFilterToggle} w-100 text-left d-block d-lg-none ${
        !props.openFilter && "mb-4"
      }`}
      onClick={props.onClick}
      aria-controls="roomFilters"
      aria-expanded={props.openFilter}
    >
      {props.children}
    </Button>
  );
};

export const StickyContainer = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Sticky stickyStyle={{ top: "151px" }} positionRecheckInterval={3}>
      {props.children}
    </Sticky>
  );
};

export const FilterTabsContainer = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Col lg={{ span: 7 }} className={`${filterTabsContainer}`}>
      {props.children}
    </Col>
  );
};

export const AccessibleFilterCol = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Col xs={6} className={`${accessibleFilterCol}`}>
      {props.children}
    </Col>
  );
};

export const LoadingSpinner: React.FC = () => (
  <div className="text-center">
    <Spinner animation="border" />
  </div>
);
