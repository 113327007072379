import { useEffect } from "react";
import { IHotelRoomsProps, IRoomRates } from "../../HotelRoomsProps";

const useLoadRoomDetails = (
  pricingInitialized: boolean,
  rates: any,
  props: IHotelRoomsProps,
  setRooms: React.Dispatch<React.SetStateAction<IRoomRates[] | null>>,
  setRoomsLoaded: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  useEffect(() => {
    let didCancel = false;
    if (pricingInitialized) {
      const roomsFetched =
        (rates && rates[props.hotel.crs_code]?.Rooms) || null;
      if (!didCancel) {
        setRooms(roomsFetched);
        setRoomsLoaded(true);
      }
    }
    return () => {
      didCancel = true;
    };
  }, [pricingInitialized, rates]);
};

export default useLoadRoomDetails;
