import * as React from "react";
import {
  styledNav,
  styledTabPanes,
  styledRow,
  featureDescription,
  rFPButton,
} from "./FeaturesTabs.module.scss";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import { ISrfpOptions } from "./FeaturesTabsProps";
import { useWebFramed } from "../../../hooks/useWebFramed";
import { GATSBY_CVENT_INSTANT_BOOK_URL } from "../../../@types/Constants";

export const StyledNav: React.FC<{ children: JSX.Element | JSX.Element[] }> = (
  props
) => {
  return (
    <Nav className={styledNav} variant="pills">
      {props.children}
    </Nav>
  );
};
export const StyledTabPanes: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = (props) => {
  return <div className={styledTabPanes}>{props.children}</div>;
};
export const StyledRow: React.FC<{ children: JSX.Element | JSX.Element[] }> = (
  props
) => {
  return <Row className={styledRow}>{props.children}</Row>;
};

const requestRFP = async (cventId: string) => {
  if (typeof document === "undefined") {
    // wait for srfp to load
    await new Promise((resolve) => setTimeout(resolve, 1000));
    // retry
    requestRFP(cventId);
  }
  const options: ISrfpOptions = {
    display: "overlay",
    container: "speed-rfp-container-wrapper",
    key: process.env.GATSBY_SPEED_RFP_FORM_KEY,
    uid: cventId,
    clear_previous: true,
    ga_xdomain: true,
    button: "none",
  };
  const srfp_widget = new srfp.widget(options);
  srfp_widget.show();
};

const InstantBookButton: React.FC<{ cventId: string | null }> = (props) => {
  const isWebFramed = useWebFramed();
  const handleSendInstantBook = (event: React.MouseEvent<HTMLElement>) => {
    const cventUrl = `${
      process.env.GATSBY_CVENT_INSTANT_BOOK_URL || GATSBY_CVENT_INSTANT_BOOK_URL
    }?accessKey=${process.env.GATSBY_CVENT_ACCESS_KEY}&venueId=${
      event.currentTarget.dataset.cventid || ""
    }`;
    window.open(cventUrl);
  };

  //The Speed RFP button is hidden in the framed view of the mobile app.
  return !isWebFramed ? (
    <Button
      onClick={handleSendInstantBook}
      className={rFPButton}
      id="instant-book-id"
      variant="secondary"
      aria-label="Instant Book Venue"
      data-cventid={props.cventId}
      disabled={!props.cventId}
    >
      Instant Book Venue
    </Button>
  ) : null;
};
const RFPButton: React.FC<{ cventId: string | null }> = (props) => {
  const isWebFramed = useWebFramed();
  const handleSendRFP = (event: React.MouseEvent<HTMLElement>) => {
    const cventId = event.currentTarget.dataset.cventid || "";
    requestRFP(cventId);
  };
  //The Speed RFP button is hidden in the framed view of the mobile app.
  return !isWebFramed ? (
    <Button
      onClick={handleSendRFP}
      className={rFPButton}
      id="rfp-button"
      variant="secondary"
      aria-label="Start an RFP"
      data-cventid={props.cventId}
      disabled={!props.cventId}
    >
      Start an RFP
    </Button>
  ) : null;
};

export const replaceGroupRFPLink = (
  desc: string,
  cventId: string | null
): JSX.Element | JSX.Element[] => {
  // Define patterns to match "Start an RFP" and "Instant Book Venue"
  const rfpPattern = /(start an rfp)/gi;
  const instantBookPattern = /(instant book venue)/gi;

  // Split the text by both patterns, preserving the delimiters
  const splitText = desc?.split(
    new RegExp(`${rfpPattern.source}|${instantBookPattern.source}`, "gi")
  );

  if (splitText?.length <= 1) {
    return <span dangerouslySetInnerHTML={{ __html: desc }} />;
  }

  return splitText?.map((element, idx) => {
    if (instantBookPattern.test(element)) {
      return <InstantBookButton key={`ibv-${idx}`} cventId={cventId} />;
    } else if (rfpPattern.test(element)) {
      return <RFPButton key={`rfp-${idx}`} cventId={cventId} />;
    } else {
      return (
        <span
          key={`desc-${idx}`}
          dangerouslySetInnerHTML={{ __html: element }}
        />
      );
    }
  });
};

// Description component is used in FeaturesTabs and SupportTiles
export const Description: React.FC<{
  text: string;
  cventid: string | null;
}> = (props) => {
  const updatedDesc = replaceGroupRFPLink(props.text, props.cventid);
  return <div className={featureDescription}>{updatedDesc}</div>;
};
